<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Form Device</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="f-w-600" for="id_brand">Brand </label>
                  <select
                    v-model="form.id_brand"
                    type="text"
                    class="form-control"
                    required
                    @change="formValidate.id_brand = ''"
                    :class="{
                      'is-valid': form.id_brand != '',
                      'is-invalid':
                        formValidate.id_brand || form.id_brand == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="brand in listBrand"
                      :key="brand.id_brand"
                      :value="brand.id_brand"
                    >
                      {{ brand.nama_brand }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="f-w-600" for="id_type">Type </label>
                  <select
                    v-model="form.id_type"
                    type="text"
                    class="form-control"
                    required
                    @change="formValidate.id_type = ''"
                    :class="{
                      'is-valid': form.id_type != '',
                      'is-invalid': formValidate.id_type || form.id_type == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="tipe in listType"
                      :key="tipe.id_type"
                      :value="tipe.id_type"
                    >
                      {{ tipe.nama_type }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="f-w-600" for="id_area">Area </label>
                  <select
                    v-model="form.id_area"
                    type="text"
                    class="form-control"
                    required
                    @change="
                      formValidate.id_area = '';
                      getLokasi();
                    "
                    :class="{
                      'is-valid': form.id_area != '',
                      'is-invalid': formValidate.id_area || form.id_area == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="area in listArea"
                      :key="area.id_area"
                      :value="area.id_area"
                    >
                      {{ area.nama_area }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="f-w-600" for="id_lokasi">Lokasi </label>
                  <select
                    v-model="form.id_lokasi"
                    type="text"
                    class="form-control"
                    required
                    @change="
                      formValidate.id_lokasi = '';
                      getSubLokasi();
                    "
                    :class="{
                      'is-valid': form.id_lokasi != '',
                      'is-invalid':
                        formValidate.id_lokasi || form.id_lokasi == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="lokasi in listLokasi"
                      :key="lokasi.id_lokasi"
                      :value="lokasi.id_lokasi"
                    >
                      {{ lokasi.nama_lokasi }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="f-w-600" for="id_sublokasi">Sub Lokasi </label>
                  <select
                    v-model="form.id_sublokasi"
                    type="text"
                    class="form-control"
                    required
                    @change="
                      formValidate.id_sublokasi = '';
                      getDevice();
                    "
                    :class="{
                      'is-valid': form.id_sublokasi != '',
                      'is-invalid':
                        formValidate.id_sublokasi || form.id_sublokasi == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="lokasi in listSubLokasi"
                      :key="lokasi.id_sublokasi"
                      :value="lokasi.id_sublokasi"
                    >
                      {{ lokasi.nama_sublokasi }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="nama_device">Nama Device </label>
                  <input
                    v-model="form.nama_device"
                    type="text"
                    class="form-control"
                    placeholder="Input Nama Device"
                    required
                    @input="formValidate.nama_device = ''"
                    :class="{
                      'is-valid': form.nama_device != '',
                      'is-invalid':
                        formValidate.nama_device || form.nama_device == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="sn_device">SN Device </label>
                  <input
                    v-model="form.sn_device"
                    type="text"
                    class="form-control"
                    placeholder="Input SN Device"
                    required
                    @input="formValidate.sn_device = ''"
                    :class="{
                      'is-valid': form.sn_device != '',
                      'is-invalid':
                        formValidate.sn_device || form.sn_device == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="bold" for="ip_device">IP Address Device</label>
                  <input
                    v-model="form.ip_device"
                    type="text"
                    class="form-control"
                    placeholder="Input IP Address Device"
                    required
                    @input="formValidate.ip_device = ''"
                    :class="{
                      'is-valid': form.ip_device != '',
                      'is-invalid':
                        formValidate.ip_device || form.ip_device == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="bold" for="username_device"
                    >Username Device</label
                  >
                  <input
                    v-model="form.username_device"
                    type="text"
                    class="form-control"
                    placeholder="Input Username Device"
                    required
                    @input="formValidate.username_device = ''"
                    :class="{
                      'is-valid': form.username_device != '',
                      'is-invalid':
                        formValidate.username_device ||
                        form.username_device == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="bold" for="password_device"
                    >Password Device</label
                  >
                  <input
                    v-model="form.password_device"
                    type="text"
                    class="form-control"
                    placeholder="Input Password Device"
                    required
                    @input="formValidate.password_device = ''"
                    :class="{
                      'is-valid': form.password_device != '',
                      'is-invalid':
                        formValidate.password_device ||
                        form.password_device == '',
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <div class="row">
              <div class="col-md-6 mb-2">
                <a
                  href="javascript:window.history.go(-1);"
                  class="btn btn-default"
                  >Kembali</a
                >
              </div>
              <div class="col-md-6">
                <button
                  class="btn btn-success float-md-right"
                  @click="postData"
                  variant="primary"
                  :disabled="loading"
                >
                  <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import userData from "../../../plugins/userData";

export default {
  mixins: [userData],
  data() {
    return {
      form: {
        id_area: "",
        id_lokasi: "",
        id_sublokasi: "",
        id_brand: "",
        id_type: "",
        nama_device: "",
        sn_device: "",
        ip_device: "",
        username_device: "",
        password_device: "",
      },
      listArea: [],
      listLokasi: [],
      listSubLokasi: [],
      listBrand: [],
      listType: [],
      formValidate: [],
      loading: false,
    };
  },
  mounted() {
    this.getBrand();
    this.getType();
    this.getArea();
    const id = this.$route.params.id;
    if (id) {
      this.fetchData(id);
    }
  },
  methods: {
    fetchData(id) {
      axios
        .get("http://localhost/api-crm-dea/v2/device/detail/" + id)
        .then((response) => {
          for (let key in response.data.data) {
            this.form[key] = response.data.data[key];
          }
          if (this.form.id_area) {
            this.getLokasi();
          }
          if (this.form.id_lokasi) {
            this.getSubLokasi();
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getBrand() {
      this.listBrand = [];
      axios
        .get("http://localhost/api-crm-dea/v2/brand/")
        .then((response) => {
          this.listBrand = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getType() {
      axios
        .get("http://localhost/api-crm-dea/v2/type/")
        .then((response) => {
          this.listType = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getArea() {
      axios
        .get("http://localhost/api-crm-dea/v2/area/option/")
        .then((response) => {
          this.listArea = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getLokasi() {
      if (this.form.id_area) {
        axios
          .get("http://localhost/api-crm-dea/v2/lokasi/option/" + this.form.id_area)
          .then((response) => {
            this.listLokasi = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
    },
    getSubLokasi() {
      if (this.form.id_lokasi) {
        axios
          .get("http://localhost/api-crm-dea/v2/sublokasi/option/" + this.form.id_lokasi)
          .then((response) => {
            this.listSubLokasi = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
    },
    postData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post("http://localhost/api-crm-dea/v2/device/update/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>